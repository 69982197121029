import { Component, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-agency-nav',
    templateUrl: './agency-nav.component.html',
    styleUrls: ['./agency-nav.component.scss']
})
export class AgencyNavComponent implements OnInit {
    isMobile: boolean = false;
    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.checkScreenWidth();
    }

    ngOnInit(): void {
        this.checkScreenWidth();
    }

    private checkScreenWidth(): void {
        this.isMobile = window.innerWidth < 768;
    }
}
