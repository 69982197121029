<!-- Nav Start-->
<header class="agency nav-abs custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a *ngIf="!isMobile" href="#" class="logo-light d-inline-block m-r-auto"><img style="max-width: 30% !important;" src="assets/img/images/logo/pc.png" alt="" class="img-fluid"></a>

                    <a *ngIf="isMobile" href="#" class="logo-light d-inline-block m-r-auto"><img src="assets/img/images/logo/mobile.png" alt="" class="img-fluid"></a>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->
