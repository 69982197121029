<!--header start-->
<section class="agency header" id="header">
    <div class="agency bg" [ngStyle]="{'background-image': 'url(assets/img/images/agency/slider/slider.png)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <h1 class="text-uppercase"><span class="bold-text">Meet My Will</span> Where wills shall be found</h1>
                            </div>
                            <div class="header-sub-text">
                                <p>Welcome to the premier Will Registry and Search platform. We specialize in providing a comprehensive database for securely searching wills, ensuring that your final wishes are preserved and easily accessible when needed.</p>
                            </div>
                            <div class="link-horizontal">
                                <ul>
                                    <li (click)="showSuccess()">
                                        <a class=" btn btn-default btn-gradient text-white"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgb(250, 245, 245);transform: msFilter"><path d="M19.665 16.811a10.316 10.316 0 0 1-1.021 1.837c-.537.767-.978 1.297-1.316 1.592-.525.482-1.089.73-1.692.744-.432 0-.954-.123-1.562-.373-.61-.249-1.17-.371-1.683-.371-.537 0-1.113.122-1.73.371-.616.25-1.114.381-1.495.393-.577.025-1.154-.229-1.729-.764-.367-.32-.826-.87-1.377-1.648-.59-.829-1.075-1.794-1.455-2.891-.407-1.187-.611-2.335-.611-3.447 0-1.273.275-2.372.826-3.292a4.857 4.857 0 0 1 1.73-1.751 4.65 4.65 0 0 1 2.34-.662c.46 0 1.063.142 1.81.422s1.227.422 1.436.422c.158 0 .689-.167 1.593-.498.853-.307 1.573-.434 2.163-.384 1.6.129 2.801.759 3.6 1.895-1.43.867-2.137 2.08-2.123 3.637.012 1.213.453 2.222 1.317 3.023a4.33 4.33 0 0 0 1.315.863c-.106.307-.218.6-.336.882zM15.998 2.38c0 .95-.348 1.838-1.039 2.659-.836.976-1.846 1.541-2.941 1.452a2.955 2.955 0 0 1-.021-.36c0-.913.396-1.889 1.103-2.688.352-.404.8-.741 1.343-1.009.542-.264 1.054-.41 1.536-.435.013.128.019.255.019.381z"></path></svg></a>
                                    </li>
                                    <li>
                                        <a href="http://meetmywill.io/articles/app-release.apk" class="btn btn-default btn-gradient text-white active"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(247, 239, 239, 1);transform: msFilter"><path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z"></path></svg></a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="set-abs abs-horizon-center girl-boy">
        <img src="assets/img/images/agency/slider/img.png" alt="" class="img-fluid" aTilt  data-tilt data-tilt-max="5"
             data-tilt-speed="400" data-tilt-perspective="500">
        <div class="plus-container plus-container6">
            <div class="plus white">
            </div>
        </div>
        <div class="plus-container plus-container7">
            <div class="plus white">
            </div>
        </div>
        <div class="plus-container plus-container8">
            <div class="plus white">
            </div>
        </div>
        <div class="circle-container">
            <div class="gradient-circle-big">
            </div>
        </div>
        <div class="circle-container small">
            <div class="gradient-circle-small">
            </div>
        </div>
    </div>
    <div class="plus-container">
        <div class="plus">
        </div>
    </div>
    <div class="plus-container plus-container2">
        <div class="plus plus-medium">
        </div>
    </div>
    <div class="plus-container plus-container3">
        <div class="plus plus-small">
        </div>
    </div>
    <div class="plus-container plus-container4">
        <div class="plus plus-small">
        </div>
    </div>
    <div class="plus-container plus-container5">
        <div class="plus plus-medium">
        </div>
    </div>
</section>
<!--header end-->
