import { Component } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-otpscreen',
    templateUrl: './otpscreen.component.html',
    styleUrls: ['./otpscreen.component.scss']
})
export class OtpscreenComponent {

    constructor(private apiService: ApiService, private router: Router,private toastr: ToastrService) { }
    otp = '';
    onOtpChange(e) {
        this.otp = e;
    }

    submit() {
        var email = sessionStorage.getItem('email_otp')
        var body = {
            "P1MD6nizSA": {
                "meta": {
                    "orgid": "7fc3862b4c6a4eb086de7219d5ea3cb2",
                    "usrid": "",
                    "usrname": "logged in username",
                    "operation": "fetch",
                    "datetimestamp": "formattedDate",
                    "client": "mobile",
                    "fingerprint": "318941532c5849449827d066fcb3eadb",
                    "ipaddress": ""
                },
                "data": {
                    "enid": "",
                    "uname": "uname",
                    "authkey": email,
                    "reportto": "",
                    "usrinfo": [{}],
                    "orgchain": {},
                    "acsdtl": {},
                    "audtinfo": {},
                    "otp": this.otp
                }
            }
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            '93e8e529aa0ab2321e76b1a114ebc999': '3f9e8dafac73406ca2b3f2f56f12f320'
        });
        this.apiService.postData(body, headers).subscribe(
            response => {
                if (response.data[0].count == "1") {
                    this.router.navigate(['/reset']);
                }else{
                    this.toastr.info('Invalid OTP');
                }
            },
            error => {
                this.toastr.error('Something Went Wrong');
            }
        );
    }

}
