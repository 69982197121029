import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-agency-header',
  templateUrl: './agency-header.component.html',
  styleUrls: ['./agency-header.component.scss']
})
export class AgencyHeaderComponent implements OnInit {

  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }

  showSuccess() {
    this.toastr.info('Coming Soon');
  }

}
