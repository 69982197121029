<div class="table-container">

    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Testator Name</th>
                <th scope="col">Testator eMail</th>
                <th scope="col">Testator DoD</th>
                <th scope="col">Requster Name</th>
                <th scope="col">Requster State</th>
                <th scope="col">Requster ID Type</th>
                <th scope="col">Death Certificate</th>
                <th scope="col">DC ID Number</th>
                <th scope="col">DC Validation Status</th>
                <th scope="col">DC Certificatenumber</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>
                    <div style="display: flex;align-items: center;">
                        <a class="btn" href="">Matched</a>
                        <a class="btn" href="">Not Found</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td>@fat</td>
                <td>@fat</td>
                <td>@fat</td>
                <td>@fat</td>
                <td>@fat</td>
                <td>@fat</td>
                <td>Jacob</td>
                <td>
                    <a class="btn" href="">Matched</a>
                    <a class="btn" href="">Not Found</a>
                </td>
            </tr>
            <tr>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
                <td>@twitter</td>
                <td>@twitter</td>
                <td>@twitter</td>
                <td>@twitter</td>
                <td>@twitter</td>
                <td>@twitter</td>
                <td>Larry</td>
                <td>
                    <a class="btn" href="">Matched</a>
                    <a class="btn" href="">Not Found</a>
                </td>
            </tr>
        </tbody>
    </table>

</div>
