<!-- Login section -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row" style="justify-content: center; text-align: center;">
            <div class="col-lg-6">
                <h3>Enter OTP</h3>
                <div class="theme-card">
                    <div class="form-group">
                        <label style="padding-bottom: 10px;">Enter Otp</label>
                        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>
                    </div>
                    <button (click)="submit()" style="padding-bottom: 10px;" type="submit"
                        class="btn primary-btn btn-default radius-0">Submit</button>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->
