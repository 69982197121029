<ng-container *ngIf="!(location == '/' || location == '/login' || location == '/forget' || location == '/otp'|| location == '/reset' || location == '/blog'
)">
  <app-sidebar></app-sidebar>
  <div class="main-content d-flex flex-column">
      <router-outlet></router-outlet>
      <div class="flex-grow-1"></div>
  </div>
</ng-container>

<ng-container *ngIf="(location == '/' || location == '/login' || location == '/forget' || location == '/otp' || location == '/reset' || location == '/blog')">
  <router-outlet></router-outlet>
</ng-container>
