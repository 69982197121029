<!-- speaker section start-->
<section class="agency format speaker expert-bg" [ngStyle]="{'background-image': 'url(assets/img/images/agency/team/expert-background.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4">
                <div class="format-container">
                    <h6 class="borders-before text-uppercase font-600">
                        <span class="">KNOW ABOUT WILL</span>
                    </h6>
                    <div class="format-head-text">
                        <h2 class="">Know "Will"<span class="block-span">Technicals</span>
                        </h2>
                    </div>
                    <div class="format-sub-text">
                        <p class="about-para">The gateway to a comprehensive exploration of wills, and the legal intricacies surrounding asset management, all aimed at safeguarding the welfare of your loved ones.</p>
                    </div>
                </div>
            </div>


            <div class="col-lg-9 col-md-8">
                <owl-carousel-o [options]="speakerCarouselOptions" class="speaker-slider">
                    <ng-container *ngFor="let expert of experts">
                        <ng-template carouselSlide class="item speker-container">
                            <div class="text-center">
                                <div class="team-img">
                                    <img [src]="expert.img_url" alt="" class="img-fluid">
                                    <div class="overlay"></div>
                                    <div class="social">
                                        <ul>
                                            <li (click)="openVerticallyCentered(content, expert.url)">
                                                <a>
                                                    <ng-template #content let-modal>
                                                        <div class="modal-body video-modal">
                                                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                              <span aria-hidden="true">&times;</span>
                                                            </button>
                                                            <iframe class="mfp-iframe" frameborder="0" allowfullscreen="" [src]="youtubeVideoUrl"></iframe>
                                                          </div>
                                                    </ng-template>
                                                    <i class="fa fa-play center-content" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="employee">
                                    <h5 class="e-name text-center font-secondary">{{expert.txt}}</h5>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- speaker section end-->
