<!--service section start-->
<section class="agency format service-bg" id="features" [ngStyle]="{'background-image': 'url(assets/img/images/agency/service/services-backgrondpng.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-4 offset-lg-1">
                <div class="center-text">
                    <div class="format-container">
                        <h6 class="borders-before text-uppercase">
                            <span class="">Our services</span>
                        </h6>
                        <div class="format-head-text">
                            <h2 class="about-font-header">Services we’re <span class="block-span">
                                            provided</span></h2>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt.</p>
                        </div>
                        <a href="#" class=" btn btn-default btn-gradient text-white">view more</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-8">
                <div class="row">
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection" [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 0 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/img/images/agency/service/1.png"
                                                                        alt="">
                                    <h5>Buisness Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection" [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 2 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/img/images/agency/service/2.png"
                                                                        alt="">
                                    <h5>Divorce Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/img/images/agency/service/3.png"
                                                                        alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection" [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 3 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/img/images/agency/service/4.png"
                                                                        alt="">
                                    <h5>Estate Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/img/images/agency/service/5.png"
                                                                        alt="">
                                    <h5>Build Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/img/images/agency/service/6.png"
                                                                        alt="">
                                    <h5>Invest Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--service section end-->
