import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/components/services/api.service';

@Component({
    selector: 'app-agency-blog',
    templateUrl: './agency-blog.component.html',
    styleUrls: ['./agency-blog.component.scss']
})
export class AgencyBlogComponent implements OnInit {

    constructor(private apiservice: ApiService) { }

    blogs = [];
    ngOnInit() {
        const body = {
            "P1MD6nizSA": {
                "meta": {
                    "orgid": "7fc3862b4c6a4eb086de7219d5ea3cb2",
                    "usrid": "",
                    "usrname": "logged in username",
                    "operation": "fetch",
                    "datetimestamp": "2023-07-15 11:09:53.496658",
                    "client": "mobile",
                    "fingerprint": "318941532c5849449827d066fcb3eadb",
                    "ipaddress": ""
                },
                "data": {
                    "appid": "dd7f17d2-1180-453a-860a-68f021a4d47e",
                }
            }
        };
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('93e8e529aa0ab2321e76b1a114ebc999', '561baa209e5949379cbfb55ae9ebc987')
        this.apiservice.postData(body, headers).subscribe(
            response => {
                this.blogs = response.data[0].appconfig.ladning.dtls[1].content;
             },
            error => { console.error('API Error:', error); }
        );
    }

    blogCarouselOptions = {
        items: 3,
        margin: 30,
        nav: false,
        dots: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        responsive: {
            0: {
                items: 1,
                margin: 10
            },
            576: {
                items: 2,
                margin: 10
            },
            991: {
                items: 3
            },
            1600: {
                items: 3
            }
        }
    }

}
