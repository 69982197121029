import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import { AgencyComponent } from './components/layouts/agency/agency.component';
import { DetailNoSidebarComponent } from './components/blog/detail-no-sidebar.component';
import { ForgetpassComponent } from './components/pages/forgetpass/forgetpass.component';
import { OtpscreenComponent } from './components/pages/otpscreen/otpscreen.component';
import { ResetscreenComponent } from './components/pages/resetscreen/resetscreen.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { SearchComponent } from './components/pages/search/search.component';

const routes: Routes = [
    { path: '', component: AgencyComponent },
    { path: 'login', component: LoginComponent },
    { path: 'blog', component: DetailNoSidebarComponent },
    { path: 'forget', component: ForgetpassComponent },
    { path: 'otp', component: OtpscreenComponent },
    { path: 'reset', component: ResetscreenComponent },
    {path: 'dashboard', component: DashboardComponent},
    {path: 'search', component: SearchComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
