import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-resetscreen',
    templateUrl: './resetscreen.component.html',
    styleUrls: ['./resetscreen.component.scss']
})
export class ResetscreenComponent {

    resetForm: FormGroup;
    constructor(private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService) {
        this.resetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    ngOnInit() { }

    submit() {
        var email = sessionStorage.getItem('email_otp')
        const password = this.resetForm.get('password').value;
        const fpassword = this.resetForm.get('email').value;

        if (fpassword == password) {
            const body = {
                "P1MD6nizSA": {
                    "meta": {
                        "orgid": "7fc3862b4c6a4eb086de7219d5ea3cb2",
                        "usrid": "",
                        "usrname": "logged in username",
                        "operation": "fetch",
                        "datetimestamp": "formattedDate",
                        "client": "mobile",
                        "fingerprint": "318941532c5849449827d066fcb3eadb",
                        "ipaddress": ""
                    },
                    "data": {
                        "enid": "",
                        "authkey": email,
                        "authvalue": password,
                        "audit": [{}],
                    }
                }
            };
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                '93e8e529aa0ab2321e76b1a114ebc999': '068811572a4a46dc80a892f8296ec11b'
            });
            this.apiService.postData(body, headers).subscribe(
                response => {
                    this.router.navigate(['/login']);
                },
                error => {
                    this.toastr.error('Something Went Wrong');
                }
            );
        }else{
            this.toastr.error('Enter Same Passwords');
        }
    }

}
