import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;

    constructor(private apiService: ApiService, private formBuilder: FormBuilder,private router: Router, private toastr: ToastrService) {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    ngOnInit() { }

    submit() {
        const email = this.loginForm.get('email').value;
        const password = this.loginForm.get('password').value;
        const body = {
            "P1MD6nizSA": {
                "meta": {
                    "orgid": "7fc3862b4c6a4eb086de7219d5ea3cb2",
                    "usrid": "",
                    "usrname": "logged in username",
                    "operation": "fetch",
                    "datetimestamp": "formattedDate",
                    "client": "mobile",
                    "fingerprint": "318941532c5849449827d066fcb3eadb",
                    "ipaddress": ""
                },
                "data": {
                    "enid": "",
                    "authkey": email,
                    "authvalue": password
                }
            }
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            '93e8e529aa0ab2321e76b1a114ebc999': '045115254d874f25bec63f1c0758b96b'
        });
        this.apiService.postData(body, headers).subscribe(
            response => {
                if(response.data.length > 0){
                    this.toastr.success('Logged in Succesfully');
                    this.router.navigate(['/dashboard']);
                }else{
                    this.toastr.info('Invalid Credentials');
                }
            },
            error => {
                this.toastr.error('Something Went Wrong');
            }
        );
    }


}
