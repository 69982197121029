import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-forgetpass',
    templateUrl: './forgetpass.component.html',
    styleUrls: ['./forgetpass.component.scss']
})
export class ForgetpassComponent {

    forgetForm: FormGroup;

    constructor(private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService) {
        this.forgetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    ngOnInit() { }

    submit() {
        const email = this.forgetForm.get('email').value;
        const body = {
            "P1MD6nizSA": {
                "meta": {
                    "orgid": "7fc3862b4c6a4eb086de7219d5ea3cb2",
                    "usrid": "",
                    "usrname": "logged in username",
                    "operation": "fetch",
                    "datetimestamp": "formattedDate",
                    "client": "mobile",
                    "fingerprint": "318941532c5849449827d066fcb3eadb",
                    "ipaddress": ""
                },
                "data": {
                    "enid": "",
                    "uname": "uname",
                    "authkey": email,
                    "reportto": "",
                    "usrinfo": [{}],
                    "orgchain": {},
                    "appid": "dd7f17d2-1180-453a-860a-68f021a4d47e",
                    "acsdtl": {},
                    "audtinfo": {}
                }
            }
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            '93e8e529aa0ab2321e76b1a114ebc999': 'cdfa376679214fbf9445f017f66a284f'
        });
        this.apiService.postData(body, headers).subscribe(
            response => {
                if(response.data.length > 0){
                    sessionStorage.setItem('email_otp', response.data[0].authkey)
                    this.router.navigate(['/otp']);
                }else{
                    this.toastr.info(`We couldn't find you in our premises, Please register yourself !!`);
                }
            },
            error => {
                this.toastr.error('Something Went Wrong');
            }
        );
    }
}
