import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/pages/login/login.component';
import { SidebarComponent } from './components/Sidebar/sidebar.component';
import { LayoutsModule } from './components/layouts/layouts.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ForgetpassComponent } from './components/pages/forgetpass/forgetpass.component';
import { OtpscreenComponent } from './components/pages/otpscreen/otpscreen.component';
import { ResetscreenComponent } from './components/pages/resetscreen/resetscreen.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { AppWebAnalyticsComponent } from './components/charts/app-web-analytics/app-web-analytics.component';
import { LineComponent } from './components/charts/line/line.component';
import { SearchComponent } from './components/pages/search/search.component';

@NgModule({
  declarations: [
    DashboardComponent,
    LoginComponent,
    AppComponent,
    SidebarComponent,
    ForgetpassComponent,
    OtpscreenComponent,
    ResetscreenComponent,
    AppWebAnalyticsComponent,
    LineComponent,
    SearchComponent,
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutsModule,
    HttpClientModule,
    NgOtpInputModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
