<!-- Start Sidemenu Area -->
<div class="sidemenu-area" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
    <div class="sidemenu-header">

        <a style="max-width: 50%;" href="#" class="logo-light d-inline-block m-r-auto"><img src="assets/img/images/logo/logo.jpg" alt=""
                class="img-fluid"></a>

        <div class="burger-menu d-none d-lg-block">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>

    <div class="sidemenu-body">
        <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>

            <li class="nav-item">
                <a routerLink="/dashboard" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Setup</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/search" class="nav-link">
                    <span class="icon"><i class='bx bxs-search'></i></span>
                    <span class="menu-title">Pending Search</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/" class="nav-link">
                    <span class="icon"><i class='bx bxs-log-out'></i></span>
                    <span class="menu-title">logout</span>
                </a>
            </li>

        </ul>
    </div>
</div>
<!-- End Sidemenu Area -->
