<!--copyright start-->
<div class="agency copyright">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="link-horizontal">
                        <ul>
                            <li>
                                <a  target="_blank" class="copyright-text" href="http://gateway.apicalin.com/niyamtnc.html">Privacy Policy</a>
                            </li>
                            <li>
                                <a  target="_blank" class="copyright-text" href="http://gateway.apicalin.com/niyamtnc.html">Terms &amp; Conditions</a>
                            </li>
                            <li>
                                <a routerLink="/login" class="copyright-text">Login</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div>
                        <!-- <h6 class="copyright-text text-white text-right">Powered by
                            <a href="https://apicalin.com/" target="_blank" style="color: #fff;">Apical Innovations</a>
                        </h6> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--copyright start-->
