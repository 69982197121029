<!-- blog section start-->
<section class="agency blog blog-bg" id="blog" [ngStyle]="{'background-image': 'url(assets/img/images/agency/blog/background.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="title">
                    <div class="main-title">
                        <h2 class="text-capitalize text-center">latest blog</h2>
                    </div>
                    <div class="sub-title">
                        <p class="text-center"> Welcome to our latest blog section, your direct path into the complex realm of wills. Our recent blog posts uncover critical elements of will creation, registration, and the search process, equipping you with the knowledge and authority to take charge of your estate. Discover the most recent articles below:</p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="blogCarouselOptions">
                    <ng-container *ngFor="let blog of blogs">
                        <ng-template carouselSlide class="item">
                            <div class="blog-agency">
                                <div class="blog-contain">
                                    <img [src]="blog.img" alt="" class="img-fluid" style="height: 200px;">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <h5 class="blog-head">{{blog.title}}</h5>
                                                <p class="para2">{{blog.description}}</p>
                                                <div class="m-t-20"><a routerLink="/blog" class="text-uppercase">read more</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- blog section end-->
