<!-- Login section -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row" style="justify-content: center; text-align: center;">
            <div class="col-lg-6">
                <h3>Forget Password ??</h3>
                <div class="theme-card">
                    <form [formGroup]="forgetForm" (ngSubmit)="submit()">
                        <div class="form-group">
                          <label style="padding-bottom: 10px;" for="email">Enter Email</label>
                          <input type="text" class="form-control" id="email" formControlName="email" placeholder="Email">
                        </div>
                        <button style="padding-bottom: 10px;" type="submit" class="btn primary-btn btn-default radius-0">Get Otp</button>
                      </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->

