<!--footer start-->
<footer class="agency footer2 p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">About Us</h3>
                    </div>
                </div>
            </div>
        </div>
        <!--footer end-->
        <div class="row">
            <div class="col-12">
                <div class="brand-container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="link-horizontal">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="list float-right">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <img src="assets/img/images/agency/brand/paytm-1.png" alt=""
                                                 class="img-fluid">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="assets/img/images/agency/brand/paytm-3.png" alt=""
                                                 class="img-fluid">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->

