<!-- Login section -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row" style="justify-content: center; text-align: center;">
            <div class="col-lg-6">
                <h3>Reset</h3>
                <div class="theme-card">
                    <form [formGroup]="resetForm" (ngSubmit)="submit()">
                        <div class="form-group">
                          <label style="padding-bottom: 10px;" for="email">Password</label>
                          <input type="password" class="form-control" id="email" formControlName="email" placeholder="Password">
                        </div>
                        <div class="form-group">
                          <label style="padding-bottom: 10px;" for="password">Confirm Password</label>
                          <input type="password" class="form-control" id="password" formControlName="password" placeholder="Confirm Password">
                        </div>
                        <button style="padding-bottom: 10px;" type="submit" class="btn primary-btn btn-default radius-0">Reset</button>
                      </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->

