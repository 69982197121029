import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = 'http://gateway.apicalin.com/api/submit';

  constructor(private http: HttpClient) { }

  postData(data: any, header: any): Observable<any> {
    const options = {
        headers: header
      };
    return this.http.post<any>(`${this.apiUrl}`, data, options);
  }
}
