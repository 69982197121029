<!--agency content start-->
<section class="agency agency-content about-bg">
    <h3 class="text-as-shadow">About <br/> us</h3>
    <div class="container">
        <div class="row">
            <div class="col-xl-9 offset-xl-3 col-md-9 offset-md-3">
                <div class="agency-header-center-container">
                    <div class="borders"></div>
                    <div class="agency-head">
                        <h3 class="agency-head-text">Will Registration <span class="block-span">and Retrieval Services</span>
                        </h3>
                    </div>
                </div>

                <p class="agency-para">With our powerful search tools, you can quickly search for the existence of a Will based on various criteria, such as the testator's name, date of registration, or unique reference number. We understand the importance of efficiency and accuracy during times of need, and we're here to make the process as smooth as possible.</p>
            </div>
        </div>
    </div>
    <div class="side-img  set-abs left-0 top-0" [@fadeInLeft]="fadeInLeft">
        <div class="plus-container plus-container9">
            <div class="plus white">
            </div>
        </div>
        <div class="plus-container plus-container10">
            <div class="plus plus-medium white">
            </div>
        </div>
        <div class="plus-container plus-container11">
            <div class="plus plus plus-small white">
            </div>
        </div>
        <img src="assets/img/images/agency/about-us/image.png" alt="" class="img-fluid about-img">
    </div>
    <img src="assets/img/images/agency/agency-abs-img.png" alt="" class="img-fluid blue-img">
</section>
<!--agency content end-->
